import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/layout';

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default () => (
  <Layout>
    <HeaderWrapper>
      <h1>Page Not Found</h1>
    </HeaderWrapper>
    <span>
      <div>
        Sorry, the page you requested could not be found. Go back to the
        <Link to="/"> homepage</Link>
        .
      </div>
    </span>
  </Layout>
);
